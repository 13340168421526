<footer id="brx-footer">
    <section id="brxe-yqegzc" class="brxe-section footer">
        <div id="brxe-cfqgoy" class="brxe-container footer-container">
            <div id="brxe-llprag" class="brxe-div footer-col"><a id="brxe-ozseve" class="brxe-image logo tag"
                    href="https://esdp-org.eu/" target="_blank" aria-current="page"><img width="180" height="49"
                        src="https://esdp-org.eu/wp-content/uploads/ESDP-Logo-Light.png" class="css-filter size-full"
                        alt="" loading="eager" decoding="async"></a>
                <div id="brxe-vuxqtw" class="brxe-text-basic footer-tagline">European Supplier Diversity Program
                    Stichting</div>
                <div id="brxe-onycrw" class="brxe-text-basic white-text footer-address">Vijzelstraat 68, 10171HL,
                    Amsterdam<br>KVK number: 91598656</div>
            </div>
            <div id="brxe-ggsxxy" class="brxe-div footer-col margin30"><a id="brxe-flpmdl"
                    class="brxe-text-basic footer-nav" href="https://esdp-org.eu/" target="_blank" aria-current="page">Home</a><a
                    id="brxe-gnkcqn" class="brxe-text-basic footer-nav" href="https://esdp-org.eu/about/" target="_blank">About</a><a
                    id="brxe-vgukdq" class="brxe-text-basic footer-nav"
                    href="https://esdp-org.eu/corporates/" target="_blank">Corporates</a></div>
            <div id="brxe-sctgwx" class="brxe-div footer-col margin10"><a id="brxe-qajvyt"
                    class="brxe-text-basic footer-nav" href="https://esdp-org.eu/businesses/" target="_blank">Businesses</a><a
                    id="brxe-pcmqfn" class="brxe-text-basic footer-nav" target="_blank" href="https://esdp-org.eu/events-opportunities/">Events</a><a
                    id="brxe-kghtmh" class="brxe-text-basic footer-nav" target="_blank" href="https://esdp-org.eu/resources/">Info
                    Hub</a></div>
            <div id="brxe-tnhpig" class="brxe-div footer-col margin10"><a id="brxe-sqjbmv"
                    class="brxe-text-basic footer-nav" href="https://esdp-org.eu/joinus/" target="_blank">Join as EMB</a><a
                    id="brxe-faoequ" class="brxe-text-basic footer-nav" target="_blank"
                    href="https://portal.esdp-org.eu/register-as-corporate">Join as Corporate</a><a id="brxe-shtlih"
                    class="brxe-text-basic footer-nav"
                    href="https://www.linkedin.com/company/europeansupplierdiversityproject/" rel="nofollow"
                    target="_blank">Linkedin</a></div>
            <div id="brxe-wyipmy" class="brxe-div footer-col margin30">
                <div id="brxe-rwfdzz" class="brxe-div designed-by-container"><a id="brxe-quddeq"
                        href="https://bosenet.com/" rel="nofollow" target="_blank" class="brxe-div">
                        <img class="bosenet-footer-logo" src="/assets/icons/bn-logo-white.png" alt="Bosenet Logo"
                            loading="lazy">
                    </a>
                    <a href="https://bosenet.com/" id="brxe-uhzuqs" target="_blank"
                        class="brxe-text-basic designed-by-text">Powered by ESDP certified EMB Bosenet</a>
                </div>
            </div>
        </div>
        <div id="brxe-qkjtnw" class="brxe-div footer-credits">
            <div id="brxe-blfoai" class="brxe-div footer-credits-container"><img width="53" height="73"
                    src="https://esdp-org.eu/wp-content/uploads/Arrow-Logo.png" class="brxe-image css-filter size-full"
                    alt="" id="brxe-sizlxb" loading="eager" decoding="async">
                <div id="brxe-xwxfli" class="brxe-div footer-credits-mob">
                    <div id="brxe-oxldtc" class="brxe-text-basic footer-credits-text">© 2024 ESDP</div>
                    <div id="brxe-zszklg" class="brxe-text footer-credits-text">
                        <p><a href="https://www.esdp-org.eu/privacy/" target="_blank">Privacy</a> | <a
                                href="https://www.esdp-org.eu/terms-conditions/" target="_blank">Terms &amp;
                                Conditions</a> | <a href="https://www.esdp-org.eu/faqs/" target="_blank">FAQs</a></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</footer>